import React from "react"
import LayoutBlog from "../pages/layoutBlog"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import StylesBlog from "../styles/blog.module.scss"
import ScrollUpButton from "react-scroll-up-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faCalendarDay, faClock } from "@fortawesome/free-solid-svg-icons";
import SEO from "../components/seo"

library.add(faUser, faCalendarDay, faClock);

export const data = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      author {
        name
        social
        avatar {
          file {
            url
          }
        }
      }
      hero {
        file {
          url
          fileName
        }
      }
      createdAt(formatString: "MMMM Do, YYYY")
      date(formatString: "MMMM Do, YYYY")
      body {
        json
          fields {
            readingTime {
              text
              minutes
              time
              words
            }
          }
      }
    }
  }
`


const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return (
          <img
            src={url}
            alt={alt}
            style={{
              display: "block",
              margin: "20px auto",
              maxWidth: "100%",
              height: "auto",
            }}
          />
        )
      },
    },
  }

  return (
    <LayoutBlog>
      <SEO title={props.data.contentfulPost.title} />
      <ScrollUpButton
        ContainerClassName={StylesBlog.iconTop}
        TransitionClassName={StylesBlog.transition}
        EasingType="easeInQuad">
      </ScrollUpButton>

      {
        props.data.contentfulPost.author.map(author => {
          return (
            <div className={`${StylesBlog.authorContent} d-flex justify-content-between`} key={author.name}>
              <div>
                <img src={author.avatar.file.url} className={StylesBlog.authorImageSmall} alt={author.name} />
                <span className={StylesBlog.author}>{author.name}</span>
              </div>
              <div className={StylesBlog.date}>
                <p>
                  <span className="pr-3"><strong><FontAwesomeIcon icon="calendar-day" /></strong>{props.data.contentfulPost.date}</span>
                  <span><strong><FontAwesomeIcon icon="clock" /></strong>{props.data.contentfulPost.body.fields.readingTime.text}</span>
                </p>
              </div>
            </div>
          )
        })
      }
      <div className={StylesBlog.blog}>
        <h1>{props.data.contentfulPost.title}</h1>
        <div>
          {documentToReactComponents(
            props.data.contentfulPost.body.json,
            options
          )}

        </div>
      </div>
    </LayoutBlog >
  )
}

export default Blog
