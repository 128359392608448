import React from 'react';
import { Container } from 'react-bootstrap';
import FooterComponent from '../components/footer';
import HeaderComponent from '../components/header';
import StyleFooter from '../styles/footer.module.scss';
import SEO from "../components/seo"

const LayoutBlog = (props) => {
    return (
        <article>
            <header>
                <Container>
                    <SEO title="Unleash your full social selling potential" />
                    <HeaderComponent />
                </Container>
            </header>
            <main>
                <Container fluid={true} style={{ backgroundColor: "white" }}>
                    <section>
                        <div className="container">
                            {props.children}
                        </div>
                    </section>
                </Container>
            </main>

            <footer>
                <Container>
                    <FooterComponent className={StyleFooter.boxBlog} />
                </Container>
            </footer>
        </article>
    );
}

export default LayoutBlog;
